var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('validation-observer',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{staticClass:"font-weight-bold",attrs:{"color":"primary","dark":""}},[_vm._v("FATHER")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Name","rules":{
                      required: true,
                      regex: /[a-zA-Z]([\w\W -.]*[a-zA-Z])?$/,
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"rounded-0",attrs:{"hide-details":"auto","label":"Father's name","error-messages":errors,"outlined":""},model:{value:(_vm.fatherName),callback:function ($$v) {_vm.fatherName=$$v},expression:"fatherName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('validation-provider',{attrs:{"name":"Father's date of birth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',_vm._g(_vm._b({staticClass:"rounded-0",attrs:{"label":"Date of birth","readonly":"","outlined":"","hide-details":"auto","error-messages":errors},model:{value:(_vm.fatherDob),callback:function ($$v) {_vm.fatherDob=$$v},expression:"fatherDob"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.menufatherDob),callback:function ($$v) {_vm.menufatherDob=$$v},expression:"menufatherDob"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menufatherDob = false}},model:{value:(_vm.fatherDob),callback:function ($$v) {_vm.fatherDob=$$v},expression:"fatherDob"}})],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Father's hometown","rules":{
                      required: true,
                      regex: /[a-zA-Z]([\w\W -.]*[a-zA-Z])?$/,
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"rounded-0",attrs:{"hide-details":"auto","label":"Hometown","error-messages":errors,"outlined":""},model:{value:(_vm.fatherHometown),callback:function ($$v) {_vm.fatherHometown=$$v},expression:"fatherHometown"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Father's mobile number 1","rules":{
                      required: true,
                      digits: 10,
                      regex: '^(0)\\d{9}$',
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"rounded-0",attrs:{"hide-details":"auto","label":"Phone number 1","error-messages":errors,"outlined":""},model:{value:(_vm.fatherNumber1),callback:function ($$v) {_vm.fatherNumber1=$$v},expression:"fatherNumber1"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Father's mobile number 2","rules":{
                      regex: _vm.fatherNumber2 ? '^(0)\\d{9}$' : '',
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"rounded-0",attrs:{"hide-details":"auto","label":"Phone number 2 (optional)","error-messages":errors,"outlined":""},model:{value:(_vm.fatherNumber2),callback:function ($$v) {_vm.fatherNumber2=$$v},expression:"fatherNumber2"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"Father's address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-textarea',{staticClass:"rounded-0",attrs:{"hide-details":"auto","outlined":"","label":"Address","error-messages":errors},model:{value:(_vm.fatherAddress),callback:function ($$v) {_vm.fatherAddress=$$v},expression:"fatherAddress"}})]}}])})],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{staticClass:"font-weight-bold",attrs:{"color":"primary","dark":""}},[_vm._v("MOTHER")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Mother's name","rules":{
                      required: true,
                      regex: /[a-zA-Z]([\w\W -.]*[a-zA-Z])?$/,
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"rounded-0",attrs:{"hide-details":"auto","label":"Mother's name","error-messages":errors,"outlined":""},model:{value:(_vm.motherName),callback:function ($$v) {_vm.motherName=$$v},expression:"motherName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('validation-provider',{attrs:{"name":"Mother's data of birth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',_vm._g(_vm._b({staticClass:"rounded-0",attrs:{"label":"Date of birth","readonly":"","outlined":"","hide-details":"auto","error-messages":errors},model:{value:(_vm.motherDob),callback:function ($$v) {_vm.motherDob=$$v},expression:"motherDob"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.menumotherDob),callback:function ($$v) {_vm.menumotherDob=$$v},expression:"menumotherDob"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menumotherDob = false}},model:{value:(_vm.motherDob),callback:function ($$v) {_vm.motherDob=$$v},expression:"motherDob"}})],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Mother's hometown","rules":{
                      required: true,
                      regex: /[a-zA-Z]([\w\W -.]*[a-zA-Z])?$/,
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"rounded-0",attrs:{"hide-details":"auto","label":"Hometown","error-messages":errors,"outlined":""},model:{value:(_vm.motherHometown),callback:function ($$v) {_vm.motherHometown=$$v},expression:"motherHometown"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Mobile number 1","rules":{
                      required: true,
                      digits: 10,
                      regex: '^(0)\\d{9}$',
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"rounded-0",attrs:{"hide-details":"auto","label":"Phone number 1","error-messages":errors,"outlined":""},model:{value:(_vm.motherNumber1),callback:function ($$v) {_vm.motherNumber1=$$v},expression:"motherNumber1"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Mobile number 2","rules":{
                      regex: _vm.motherNumber2 ? '^(0)\\d{9}$' : '',
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"rounded-0",attrs:{"hide-details":"auto","label":"Phone number 2 (optional)","error-messages":errors,"outlined":""},model:{value:(_vm.motherNumber2),callback:function ($$v) {_vm.motherNumber2=$$v},expression:"motherNumber2"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"Mother's address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-textarea',{staticClass:"rounded-0",attrs:{"hide-details":"auto","outlined":"","label":"Address","error-messages":errors},model:{value:(_vm.motherAddress),callback:function ($$v) {_vm.motherAddress=$$v},expression:"motherAddress"}})]}}])})],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[(!_vm.props.user_id)?_c('v-btn',{attrs:{"loading":_vm.loading,"disabled":_vm.loading,"color":"primary"},on:{"click":_vm.sendData},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',[_vm._v("Saving...")]),_c('span',{staticClass:"custom-loader"},[_c('v-icon',{attrs:{"light":""}},[_vm._v("mdi-cached")])],1)]},proxy:true}],null,false,776031462)},[_vm._v(" Save and Continue ")]):_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.addNewParent}},[_vm._v(" save changes ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }