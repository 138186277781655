var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"flat":""}},[(_vm.props.user_id)?_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v(" Next Of Kin ")]):_vm._e(),_c('v-card-text',{staticClass:"px-0"},[_c('validation-observer',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-card',{staticClass:"pa-4",attrs:{"outlined":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Name","rules":{
                      required: true,
                      regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"rounded-0",attrs:{"hide-details":"auto","label":"Next of Kin's name","error-messages":errors,"outlined":""},model:{value:(_vm.kinName),callback:function ($$v) {_vm.kinName=$$v},expression:"kinName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('validation-provider',{attrs:{"name":"Date of birth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',_vm._g(_vm._b({staticClass:"rounded-0",attrs:{"label":"Date of birth","readonly":"","outlined":"","hide-details":"auto","error-messages":errors},model:{value:(_vm.kinDob),callback:function ($$v) {_vm.kinDob=$$v},expression:"kinDob"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.menuDob),callback:function ($$v) {_vm.menuDob=$$v},expression:"menuDob"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menuDob = false}},model:{value:(_vm.kinDob),callback:function ($$v) {_vm.kinDob=$$v},expression:"kinDob"}})],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Hometown","rules":{
                      required: true,
                      regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"rounded-0",attrs:{"hide-details":"auto","label":"Hometown","error-messages":errors,"outlined":""},model:{value:(_vm.kinHometown),callback:function ($$v) {_vm.kinHometown=$$v},expression:"kinHometown"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Mobile number 1","rules":{
                      required: true,
                      digits: 10,
                      regex: '^(0)\\d{9}$',
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"rounded-0",attrs:{"hide-details":"auto","label":"Mobile number 1","error-messages":errors,"outlined":""},model:{value:(_vm.kinNumber1),callback:function ($$v) {_vm.kinNumber1=$$v},expression:"kinNumber1"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Mobile number 2","rules":{
                      regex: _vm.kinNumber2 ? '^(0)\\d{9}$' : '',
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"rounded-0",attrs:{"hide-details":"auto","label":"Mobile number 2 (optional)","error-messages":errors,"outlined":""},model:{value:(_vm.kinNumber2),callback:function ($$v) {_vm.kinNumber2=$$v},expression:"kinNumber2"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-textarea',{staticClass:"rounded-0",attrs:{"hide-details":"auto","outlined":"","label":"Address","error-messages":errors},model:{value:(_vm.kinAddress),callback:function ($$v) {_vm.kinAddress=$$v},expression:"kinAddress"}})]}}])})],1)],1)],1)],1),_c('v-row',{staticClass:"mt-3"},[_c('v-col',[(!_vm.props.user_id)?_c('v-btn',{attrs:{"loading":_vm.loading,"disabled":_vm.loading,"color":"primary"},on:{"click":_vm.sendData},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',[_vm._v("Saving...")]),_c('span',{staticClass:"custom-loader"},[_c('v-icon',{attrs:{"light":""}},[_vm._v("mdi-cached")])],1)]},proxy:true}],null,false,776031462)},[_vm._v(" Save and Continue ")]):_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.addNewKin}},[_vm._v(" save changes ")])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }