<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card flat>
          <v-toolbar color="primary" dark primary-title v-if="props.user_id">
            Death Gratuity
          </v-toolbar>
          <v-card-text class="px-0">
            <validation-observer ref="form">
              <v-form @submit.prevent>
                <v-slide-y-transition>
                  <v-row>
                    <v-col cols="12" lg="12">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Name"
                        :rules="{
                          required: true,
                          regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                        }"
                      >
                        <v-text-field
                          hide-details="auto"
                          label="Name of Witness"
                          v-model="witnessName"
                          :error-messages="errors"
                          outlined
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-slide-y-transition>

                <v-row>
                  <v-col>
                    <v-card flat>
                      <v-card-title primary-title> NOMINEE(S) </v-card-title>
                      <validation-observer ref="observer">
                        <v-slide-y-transition>
                          <div>
                            <v-row
                              v-for="(task, i) in basketNominee"
                              justify="center"
                              align="center"
                              :key="i"
                              :class="{ mycolor: task.duplicate }"
                            >
                              <v-col cols="12" lg="4">
                                <validation-provider
                                  v-slot="{ errors }"
                                  :vid="`name-${i}`"
                                  :rules="{
                                    required: true,
                                    regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                                  }"
                                  :name="task.name"
                                >
                                  <v-text-field
                                    outlined
                                    hide-details="auto"
                                    label="Name of nominee"
                                    v-model="task.name"
                                    :error-messages="errors"
                                  ></v-text-field>
                                </validation-provider>
                              </v-col>

                              <v-col cols="12" lg="2">
                                <validation-provider
                                  v-slot="{ errors }"
                                  :name="task.relationship"
                                  :rules="{
                                    required: true,
                                  }"
                                >
                                  <v-select
                                    hide-details="auto"
                                    outlined
                                    v-model="task.relationship"
                                    :error-messages="errors"
                                    label="Relationship"
                                    :items="relationshipArray"
                                  ></v-select>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="3">
                                <validation-provider
                                  v-slot="{ errors }"
                                  :name="task.address"
                                  rules="required"
                                >
                                  <v-text-field
                                    hide-details="auto"
                                    outlined
                                    v-model="task.address"
                                    :error-messages="errors"
                                    label="Address"
                                  ></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="2">
                                <validation-provider
                                  v-slot="{ errors }"
                                  :name="task.percentage"
                                  rules="required|max_value:100"
                                >
                                  <v-text-field
                                    hide-details="auto"
                                    outlined
                                    v-model="task.percentage"
                                    :error-messages="errors"
                                    label="Percent"
                                    suffix="%"
                                  ></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="1" class="text-center">
                                <v-slide-y-reverse-transition>
                                  <v-btn
                                    icon
                                    v-if="i + 1 === basketNominee.length"
                                    color="success"
                                    @click="newRow()"
                                    :disabled="!valid"
                                    small
                                  >
                                    <v-icon>mdi-plus-circle</v-icon>
                                  </v-btn>
                                </v-slide-y-reverse-transition>
                                <v-slide-x-transition>
                                  <v-btn
                                    v-if="i + 1 >= 2"
                                    icon
                                    small
                                    color="red"
                                    @click="removeRow(i)"
                                  >
                                    <v-icon> mdi-delete-outline </v-icon>
                                  </v-btn>
                                </v-slide-x-transition>
                              </v-col>
                            </v-row>
                          </div>
                        </v-slide-y-transition>
                      </validation-observer>
                    </v-card>
                  </v-col>
                </v-row>
              </v-form>
            </validation-observer>
          </v-card-text>
          <Response v-if="actionResponse">
            <template v-slot:header>{{ msgHeader }}</template>
            <template v-slot:body>{{ msgBody }}</template>
            <template v-slot:body1 v-if="msgBody1">{{ msgBody1 }}</template>
            <template v-slot:icon>{{ msgIcon }}</template>
          </Response>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          :loading="loading"
          :disabled="loading"
          v-if="!props.user_id"
          color="primary"
          @click="sendData"
        >
          Save and Continue
          <template v-slot:loader>
            <span>Saving...</span>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
        <v-btn
          @click="addNewDeath"
          color="success"
          v-else
          class="font-weight-bold"
        >
          save changes
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { defineComponent, provide, reactive, toRefs, watch } from "vue";
  import Response from "@/components/ActionResponse/Response";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
  import { required, max_value } from "vee-validate/dist/rules";

  extend("required", {
    ...required,
    message: "{_field_} can not be empty",
  });
  extend("max_value", {
    ...max_value,
    message: "{_field_} should be greater than 100%",
  });
  extend("distinct", {
    params: ["target"],
    validate(value, { target }) {
      return value != target;
    },
    message: "Fields can not be the same",
  });

  export default defineComponent({
    components: { Response, ValidationProvider, ValidationObserver },
    props: ["user_id"],
    setup(props, context) {
      const { userGratuity } = useActions(["userGratuity"]);
      const { getters_single_staff } = useGetters(["getters_single_staff"]);
      const spouseChildrenForm = reactive({
        loading: false,
        observer: null,
        valid: true,
        basketNominee: [
          {
            name: null,
            percentage: null,
            relationship: null,
            address: null,
          },
        ],
        relationshipArray: [
          "Son",
          "Daughter",
          "Uncle",
          "Auntie",
          "Father",
          "Mother",
          "Niece",
          "Nephew",
        ],
        witnessName: null,
        form: null,
        menuDob: false,
        actionResponse: false,
        color: null,
        msgHeader: null,
        msgBody: null,
        msgIcon: null,
        msgBody1: null,
        total: 0,
      });

      const {
        loading,
        basketNominee,
        observer,
        witnessName,
        actionResponse,
        color,
        msgHeader,
        msgBody,
        msgBody1,
        msgIcon,
        total,
        valid,
        form,
      } = toRefs(spouseChildrenForm);

      watch(
        () => [...basketNominee.value],
        () => {
          actionResponse.value = false;

          const uniqueItems = [];
          basketNominee.value.filter((item) => {
            if (
              uniqueItems.find(
                (i) =>
                  i.name === item.name &&
                  i.relationship === item.relationship &&
                  i.address === item.address
              )
            ) {
              item["duplicate"] = true;
              valid.value = false;
              actionResponse.value = true;
              color.value = "error";
              msgBody.value = "Duplicate Found";
              msgIcon.value = "mdi-close-circle";
              return true;
            }
            uniqueItems.push(item);
            delete item["duplicate"];
            valid.value = true;
            return false;
          });

          total.value = basketNominee.value.reduce(
            (acc, item) => acc + parseInt(item.percentage),
            0
          );
        },
        { deep: true }
      );

      const findTotal = (number) => {
        if (number > 100 || number === 0) {
          actionResponse.value = true;
          color.value = "error";
          msgHeader.value = "Error";
          msgBody.value = `The percentage should equal to 100.`;
          msgBody1.value = `Total percentage is ${number}`;
          msgIcon.value = "mdi-alert-decagram-outline";
          return true;
        }
      };

      const totalPercent = (number) => {
        if (number !== 100) {
          actionResponse.value = true;
          color.value = "error";
          msgHeader.value = "Error";
          msgBody.value = `The percentage should equal to 100.`;
          msgBody1.value = `Total percentage is ${number}`;
          msgIcon.value = "mdi-alert-decagram-outline";
          return true;
        }
      };

      const newRow = async () => {
        actionResponse.value = false;
        await observer.value.validate().then((result) => {
          actionResponse.value = false;

          if (findTotal(total.value)) return;

          if (result === true) {
            basketNominee.value.push({
              name: null,
              percentage: null,
              relationship: null,
              address: null,
            });
          }
        });
      };

      provide("color", color);

      const removeRow = (i) => {
        basketNominee.value.splice(i, 1);
      };

      const sendData = async () => {
        actionResponse.value = false;
        await form.value.validate().then((result) => {
          if (result === true) {
            if (totalPercent(total.value)) return;
            loading.value = true;
            let data = {
              nominee: basketNominee.value,
              witness: witnessName.value,
              user_id: getters_single_staff.value.id,
            };

            userGratuity(data)
              .then(() =>
                context.emit("page", {
                  bool: true,
                  page: 8,
                  name: "death",
                })
              )
              .catch(() => context.emit("page", { bool: false, page: 8 }))
              .finally(() => (loading.value = false));
          } else context.emit("page", { bool: false, page: 8 });
        });
      };

      const addNewDeath = async () => {
        actionResponse.value = false;
        await form.value.validate().then((result) => {
          if (result) {
            if (totalPercent(total.value)) return;

            let data = {
              nominee: basketNominee.value,
              witness: witnessName.value,
              user_id: props.user_id,
            };
            userGratuity(data).catch((e) => console.log(e));
          }
        });
      };

      return {
        ...toRefs(spouseChildrenForm),
        sendData,
        newRow,
        removeRow,
        props,
        addNewDeath,
      };
    },
  });
</script>
<style scoped>
  .mycolor {
    border: 1px solid red;
    border-radius: 5px;
    animation: shake-animation 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
  }
  @keyframes shake-animation {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
</style>
