<template>
  <v-card outlined>
    <v-toolbar
      elevation="1"
      color="primary"
      class="secondary--text"
      v-if="props.user_id"
    >
      <v-toolbar-title>EMPLOYMENT</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="mt-4">
      <validation-observer ref="observer">
        <v-form @submit.prevent>
          <v-row>
            <v-col cols="12" lg="4">
              <validation-provider
                v-slot="{ errors }"
                name="Employee ID"
                rules="required|alpha_dash"
                ref="uniqueEmployeeId"
              >
                <v-text-field
                  class="rounded-0"
                  vid="employee_id"
                  hide-details="auto"
                  :messages="checkingText"
                  @keyup="ifIdUnique"
                  outlined
                  label="Employee ID"
                  v-model="employee_id"
                  :error-messages="errors"
                >
                  <template slot="append" v-if="checkingText">
                    <v-progress-circular indeterminate></v-progress-circular>
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="4">
              <validation-provider
                v-slot="{ errors }"
                name="Position"
                rules="required"
              >
                <v-autocomplete
                  hide-details="auto"
                  outlined
                  :items="getters_staff_positions"
                  class="rounded-0"
                  item-text="position"
                  item-value="id"
                  label="Position of Appointment"
                  :loading="loading"
                  v-model="rank"
                  :error-messages="errors"
                  @change="getStaffPositionDetails"
                ></v-autocomplete>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="4">
              <validation-provider
                v-slot="{ errors }"
                name="Staff Division"
                rules="required"
              >
                <v-text-field
                  hide-details="auto"
                  outlined
                  class="rounded-0"
                  disabled
                  :value="division"
                  label="Staff Division"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="12" lg="4">
              <validation-provider
                v-slot="{ errors }"
                name="Staff Category"
                rules="required"
              >
                <v-text-field
                  hide-details="auto"
                  outlined
                  class="rounded-0"
                  disabled
                  :value="category"
                  label="Staff Category"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="12" lg="4">
              <validation-provider
                v-slot="{ errors }"
                name="Belongs To"
                rules="required"
              >
                <v-autocomplete
                  hide-details="auto"
                  class="rounded-0"
                  outlined
                  :items="getters_organogram"
                  item-value="id"
                  item-text="office_name"
                  label="Belongs To"
                  :loading="loading"
                  v-model="organogram_id"
                  :error-messages="errors"
                >
                  <template #item="{ item }">
                    <span v-if="item.office_type.toLowerCase() == 'office'">
                      <b class="black--text">
                        {{ `${item.office_type} of  the` }}
                      </b>
                      {{ item.office_name }}
                    </span>
                    <span v-else>
                      <b class="black--text">
                        {{ `${item.office_type} of ` }}
                      </b>
                      {{ item.office_name }}
                    </span>
                  </template>
                  <template #selection="{ item }">
                    <span v-if="item.office_type.toLowerCase() == 'office'">
                      <b class="black--text">
                        {{ `${item.office_type} of  the` }}
                      </b>
                      {{ item.office_name }}
                    </span>
                    <span v-else>
                      <b class="error--text">
                        {{ `${item.office_type} of ` }}
                      </b>
                      {{ item.office_name }}
                    </span>
                  </template>
                </v-autocomplete>
              </validation-provider>
            </v-col>

            <v-col cols="12" lg="4">
              <validation-provider
                v-slot="{ errors }"
                name="Campus"
                rules="required"
              >
                <v-select
                  :items="getters_campus"
                  item-text="campus_name"
                  item-value="id"
                  class="rounded-0"
                  hide-details="auto"
                  label="Campus"
                  data-vv-name="campus"
                  :error-messages="errors"
                  outlined
                  v-model="campus"
                ></v-select>
              </validation-provider>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col cols="12" lg="4">
              <validation-provider
                v-slot="{ errors }"
                name="Faculty/Directorate"
                rules="required"
              >
                <v-select
                  :items="getters_faculty"
                  item-value="id"
                  item-text="faculty_name"
                  hide-details="auto"
                  outlined
                  label="Faculty/Directorate"
                  @change="selectFaculty"
                  :error-messages="errors"
                  data-vv-name="facdir"
                  v-model="facdir"
                ></v-select>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="4">
              <validation-provider
                name="Department"
                v-slot="{ errors }"
                :rules="`${
                  facdir !== null && department === null ? 'required' : ''
                }`"
              >
                <v-select
                  :items="departments"
                  item-value="id"
                  item-text="department_name"
                  hide-details="auto"
                  label="Department"
                  :disabled="isDepartment"
                  data-vv-name="department"
                  :error-messages="errors"
                  v-model="department"
                  outlined
                ></v-select>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="4">
              <validation-provider
                v-slot="{ errors }"
                name="Campus"
                rules="required"
              >
                <v-select
                  :items="getters_campus"
                  item-text="campus_name"
                  item-value="id"
                  class="rounded-0"
                  hide-details="auto"
                  label="Campus"
                  data-vv-name="campus"
                  :error-messages="errors"
                  outlined
                  v-model="campus"
                ></v-select>
              </validation-provider>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="12" lg="4">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Date of birth"
                    rules="required"
                  >
                    <v-text-field
                      v-model="employmentdate"
                      label="Employment date"
                      readonly
                      class="rounded-0"
                      outlined
                      hide-details="auto"
                      :error-messages="errors"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="employmentdate"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" lg="4">
              <validation-provider
                v-slot="{ errors }"
                name="SSNIT ID"
                rules="required|alpha_num"
              >
                <v-text-field
                  hide-details="auto"
                  label="SSNIT ID"
                  class="rounded-0"
                  outlined
                  :error-messages="errors"
                  v-model="ssnit"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="4">
              <validation-provider
                v-slot="{ errors }"
                name="TIN number"
                rules="required|alpha_num"
              >
                <v-text-field
                  hide-details="auto"
                  class="rounded-0"
                  label="TIN Number"
                  :error-messages="errors"
                  v-model="tin"
                  outlined
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col>
              <v-btn
                v-if="!props.user_id"
                color="primary"
                :loading="loading1"
                :disabled="loading1"
                @click="sendData"
              >
                Save and Continue
                <template v-slot:loader>
                  <span>Saving...</span>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn v-else color="success" @click="addNewEmp">
                save changes
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>
<script>
  import { defineComponent, reactive, toRefs } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";

  import Api from "@/apis/Api";
  import debounce from "@/debounce/debounce";

  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
  import { required, alpha_dash, alpha_num } from "vee-validate/dist/rules";
  extend("required", { ...required });
  extend("alpha_num", { ...alpha_num });

  extend("alpha_dash", {
    ...alpha_dash,
    message: "{_field_} must be valid",
  });

  export default defineComponent({
    props: ["user_id"],
    components: { ValidationProvider, ValidationObserver },
    setup(props, context) {
      const {
        //getFaculty,
        getCampus,
        userEmployment,
        getStaffPosition,
        getOrganogram,
      } = useActions([
        //"getFaculty",
        "getCampus",
        "userEmployment",
        "getStaffPosition",
        "getOrganogram",
      ]);

      const {
        //getters_faculty,
        getters_campus,
        getters_staff_positions,
        getters_organogram,
        getters_single_staff,
      } = useGetters([
        //"getters_faculty",
        "getters_campus",
        "getters_staff_positions",
        "getters_organogram",
        "getters_single_staff",
      ]);

      Promise.all([
        getOrganogram(),
        // getFaculty(),
        getCampus(),
        getStaffPosition(),
      ]).then(() => (loading.value = false));

      const employmentForm = reactive({
        rank: null,
        isDepartment: true,
        valid: true,
        job_title: null,
        facdir: null,
        department: null,
        campus: null,
        employee_id: null,
        ssnit: null,
        tin: null,
        employmentdate: null,
        menu: false,
        staff_category: null,
        observer: null,
        organogram_id: null,
        departments: [],
        checkingText: "",
        uniqueEmployeeId: null,
        category: null,
        division: null,
        loading: true,
        loading1: false,
      });

      const {
        organogram_id,
        loading,
        category,
        division,
        observer,
        // departments,
        // isDepartment,
        rank,
        department,
        campus,
        employee_id,
        ssnit,
        employmentdate,
        tin,
        facdir,
        checkingText,
        uniqueEmployeeId,
        loading1,
      } = toRefs(employmentForm);

      const ifIdUnique = debounce(async () => {
        checkingText.value = "checking availability...";
        await Api()
          .post("isemployeeidunique", { employee_id: employee_id.value })
          .then((res) => {
            if (res.data === 1) {
              checkingText.value = null;
              uniqueEmployeeId.value.setErrors(
                `Employee ID: ${employee_id.value} Already Exists...`
              );
              return;
            } else {
              uniqueEmployeeId.value.reset();
              checkingText.value = null;
            }
          });
      });

      const sendData = async () => {
        await observer.value.validate().then((result) => {
          if (result) {
            loading1.value = true;
            let data = {
              staff_position_id: rank.value,
              // faculty_id: facdir.value,
              // department_id: department.value,
              campus_id: campus.value,
              employee_id: employee_id.value,
              ssnit_id: ssnit.value,
              tin_number: tin.value,
              employment_date: employmentdate.value,
              organogram_id: organogram_id.value,
              user_id: getters_single_staff.value.id,
            };
            userEmployment(data)
              .then(() =>
                context.emit("page", {
                  bool: true,
                  page: 3,
                  name: "employment",
                })
              )
              .catch(() => context.emit("page", { bool: false, page: 3 }))
              .finally(() => (loading1.value = false));
          } else {
            context.emit("page", { bool: false, page: 3 });
          }
        });
      };

      /* const selectFaculty = (id) => {
                    let faculty = getters_faculty.value.find((faculty) => faculty.id === id);
                    departments.value = faculty.departments;
                    if (departments.value.length > 0) isDepartment.value = false;
                  };
             */
      const addNewEmp = async () => {
        await observer.value.validate().then((result) => {
          if (result) {
            let data = {
              user_id: props.user_id,
              employee_id: employee_id.value,
              staff_position_id: rank.value,
              faculty_id: facdir.value,
              department_id: department.value,
              campus_id: campus.value,
              ssnit_id: ssnit.value,
              tin_number: tin.value,
              employment_date: employmentdate.value,
              organogram_id: organogram_id.value,
            };
            userEmployment(data);
          }
        });
      };

      const getStaffPositionDetails = (id) => {
        const getCategory = getters_staff_positions.value.find(
          (position) => position.id === id
        );
        category.value = getCategory?.staff_category?.category;
        division.value = getCategory?.staff_division?.division;
      };

      return {
        ...toRefs(employmentForm),
        sendData,
        // getters_faculty,
        // selectFaculty,
        getters_campus,
        addNewEmp,
        props,
        ifIdUnique,
        getters_staff_positions,
        getStaffPositionDetails,
        getters_organogram,
      };
    },
  });
</script>
