<template>
  <v-container fluid>
    <validation-observer ref="observer">
      <v-form @submit.prevent>
        <v-row>
          <v-col>
            <v-card outlined>
              <v-toolbar class="font-weight-bold" color="primary" dark
                >FATHER</v-toolbar
              >
              <v-card-text>
                <v-row>
                  <v-col cols="12" lg="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Name"
                      :rules="{
                        required: true,
                        regex: /[a-zA-Z]([\w\W -.]*[a-zA-Z])?$/,
                      }"
                    >
                      <v-text-field
                        hide-details="auto"
                        label="Father's name"
                        class="rounded-0"
                        v-model="fatherName"
                        :error-messages="errors"
                        outlined
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <v-menu
                      v-model="menufatherDob"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Father's date of birth"
                          rules="required"
                        >
                          <v-text-field
                            v-model="fatherDob"
                            label="Date of birth"
                            readonly
                            class="rounded-0"
                            outlined
                            hide-details="auto"
                            :error-messages="errors"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="fatherDob"
                        @input="menufatherDob = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Father's hometown"
                      :rules="{
                        required: true,
                        regex: /[a-zA-Z]([\w\W -.]*[a-zA-Z])?$/,
                      }"
                    >
                      <v-text-field
                        hide-details="auto"
                        class="rounded-0"
                        label="Hometown"
                        :error-messages="errors"
                        v-model="fatherHometown"
                        outlined
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Father's mobile number 1"
                      :rules="{
                        required: true,
                        digits: 10,
                        regex: '^(0)\\d{9}$',
                      }"
                    >
                      <v-text-field
                        hide-details="auto"
                        label="Phone number 1"
                        class="rounded-0"
                        v-model="fatherNumber1"
                        :error-messages="errors"
                        outlined
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" lg="4"
                    ><validation-provider
                      v-slot="{ errors }"
                      name="Father's mobile number 2"
                      :rules="{
                        regex: fatherNumber2 ? '^(0)\\d{9}$' : '',
                      }"
                    >
                      <v-text-field
                        hide-details="auto"
                        class="rounded-0"
                        label="Phone number 2 (optional)"
                        v-model="fatherNumber2"
                        :error-messages="errors"
                        outlined
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Father's address"
                      rules="required"
                    >
                      <v-textarea
                        class="rounded-0"
                        hide-details="auto"
                        outlined
                        label="Address"
                        :error-messages="errors"
                        v-model="fatherAddress"
                      ></v-textarea>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card outlined>
              <v-toolbar class="font-weight-bold" color="primary" dark
                >MOTHER</v-toolbar
              >
              <v-card-text>
                <v-row>
                  <v-col cols="12" lg="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Mother's name"
                      :rules="{
                        required: true,
                        regex: /[a-zA-Z]([\w\W -.]*[a-zA-Z])?$/,
                      }"
                    >
                      <v-text-field
                        hide-details="auto"
                        label="Mother's name"
                        class="rounded-0"
                        v-model="motherName"
                        :error-messages="errors"
                        outlined
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <v-menu
                      v-model="menumotherDob"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Mother's data of birth"
                          rules="required"
                        >
                          <v-text-field
                            v-model="motherDob"
                            label="Date of birth"
                            readonly
                            outlined
                            hide-details="auto"
                            :error-messages="errors"
                            class="rounded-0"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="motherDob"
                        @input="menumotherDob = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Mother's hometown"
                      :rules="{
                        required: true,
                        regex: /[a-zA-Z]([\w\W -.]*[a-zA-Z])?$/,
                      }"
                    >
                      <v-text-field
                        hide-details="auto"
                        label="Hometown"
                        :error-messages="errors"
                        class="rounded-0"
                        v-model="motherHometown"
                        outlined
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Mobile number 1"
                      :rules="{
                        required: true,
                        digits: 10,
                        regex: '^(0)\\d{9}$',
                      }"
                    >
                      <v-text-field
                        hide-details="auto"
                        label="Phone number 1"
                        v-model="motherNumber1"
                        class="rounded-0"
                        :error-messages="errors"
                        outlined
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Mobile number 2"
                      :rules="{
                        regex: motherNumber2 ? '^(0)\\d{9}$' : '',
                      }"
                    >
                      <v-text-field
                        hide-details="auto"
                        label="Phone number 2 (optional)"
                        v-model="motherNumber2"
                        class="rounded-0"
                        :error-messages="errors"
                        outlined
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Mother's address"
                      rules="required"
                    >
                      <v-textarea
                        hide-details="auto"
                        class="rounded-0"
                        outlined
                        label="Address"
                        :error-messages="errors"
                        v-model="motherAddress"
                      ></v-textarea>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn
              v-if="!props.user_id"
              :loading="loading"
              :disabled="loading"
              color="primary"
              @click="sendData"
            >
              Save and Continue
              <template v-slot:loader>
                <span>Saving...</span>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
            <v-btn @click="addNewParent" color="success" v-else>
              save changes
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
  </v-container>
</template>
<script>
  import { defineComponent, reactive, toRefs } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
  import { digits, regex, required } from "vee-validate/dist/rules";

  extend("required", {
    ...required,
    message: "{_field_} can not be empty",
  });

  extend("digits", {
    ...digits,
    message: "{_field_} needs to be {length} digits starting with zero(0). ",
  });

  extend("regex", {
    ...regex,
    message: "{_field_}  must be valid",
  });

  export default defineComponent({
    props: ["user_id"],
    components: { ValidationProvider, ValidationObserver },
    setup(props, context) {
      const { userParent } = useActions(["userParent"]);
      const { getters_single_staff } = useGetters(["getters_single_staff"]);
      const parentNextOfKin = reactive({
        observer: null,
        valid: true,
        fatherName: null,
        motherName: null,
        fatherDob: null,
        motherDob: null,
        fatherAddress: null,
        motherAddress: null,
        fatherNumber1: null,
        motherNumber1: null,
        fatherNumber2: null,
        motherNumber2: null,
        fatherHometown: null,
        motherHometown: null,
        menumotherDob: false,
        menufatherDob: false,
        loading: false,
      });

      const {
        observer,
        fatherName,
        motherName,
        fatherDob,
        motherDob,
        fatherAddress,
        motherAddress,
        fatherNumber1,
        motherNumber1,
        fatherNumber2,
        motherNumber2,
        fatherHometown,
        loading,
        motherHometown,
      } = toRefs(parentNextOfKin);

      const sendData = async () => {
        await observer.value.validate().then((result) => {
          if (result) {
            loading.value = true;
            let data = {
              father_name: fatherName.value,
              mother_name: motherName.value,
              father_dob: fatherDob.value,
              mother_dob: motherDob.value,
              father_address: fatherAddress.value,
              mother_address: motherAddress.value,
              father_number1: fatherNumber1.value,
              mother_number1: motherNumber1.value,
              father_number2: fatherNumber2.value,
              mother_number2: motherNumber2.value,
              father_hometown: fatherHometown.value,
              mother_hometown: motherHometown.value,
              user_id: getters_single_staff.value.id,
            };
            userParent(data)
              .then(() =>
                context.emit("page", {
                  bool: true,
                  page: 6,
                  name: "parent",
                })
              )
              .catch(() => context.emit("page", { bool: false, page: 6 }))
              .finally(() => (loading.value = false));
          } else context.emit("page", { bool: false, page: 6 });
        });
      };

      const addNewParent = async () => {
        await observer.value.validate().then((result) => {
          if (result) {
            let data = {
              father_name: fatherName.value,
              mother_name: motherName.value,
              father_dob: fatherDob.value,
              mother_dob: motherDob.value,
              father_address: fatherAddress.value,
              mother_address: motherAddress.value,
              father_number1: fatherNumber1.value,
              mother_number1: motherNumber1.value,
              father_number2: fatherNumber2.value,
              mother_number2: motherNumber2.value,
              father_hometown: fatherHometown.value,
              mother_hometown: motherHometown.value,
              user_id: props.user_id,
            };
            userParent(data);
          }
        });
      };

      return {
        ...toRefs(parentNextOfKin),
        sendData,
        addNewParent,
        props,
      };
    },
  });
</script>
