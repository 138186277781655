<template>
  <v-card outlined class="pa-4">
    <v-card-title v-if="props.user_id"> BANK DETAILS </v-card-title>

    <validation-observer ref="observer">
      <v-form @submit.prevent ref="form1">
        <v-row>
          <v-col cols="12" lg="3">
            <validation-provider
              v-slot="{ errors }"
              name="Account Number"
              rules="required|numeric"
            >
              <v-text-field
                hide-details="auto"
                class="rounded-0"
                outlined
                label="Account Number"
                v-model="accountNumber"
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" lg="5">
            <validation-provider
              v-slot="{ errors }"
              name="Name of bank"
              rules="required|alpha_spaces"
            >
              <v-text-field
                hide-details="auto"
                class="rounded-0"
                outlined
                label="Name of Bank"
                v-model="bankName"
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" lg="4">
            <validation-provider
              v-slot="{ errors }"
              name="Branch name"
              rules="required|alpha_spaces"
            >
              <v-text-field
                hide-details="auto"
                outlined
                class="rounded-0"
                label="Name of branch"
                v-model="branch"
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>

        <v-row class="mt-3">
          <v-col>
            <v-btn
              v-if="!props.user_id"
              color="primary"
              :disabled="loading"
              :loading="loading"
              @click="sendData"
            >
              Save and Continue
              <template v-slot:loader>
                <span>Saving...</span>

                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
            <v-btn v-else color="success" @click="addNewBank">
              save changes
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
  </v-card>
</template>
<script>
  import { defineComponent, reactive, toRefs } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
  import {
    required,
    alpha_dash,
    alpha_spaces,
    numeric,
  } from "vee-validate/dist/rules";

  extend("numeric", { ...numeric, message: "Email must be valid" });

  extend("required", {
    ...required,
    message: "{_field_} can not be empty",
  });

  extend("alpha_dash", {
    ...alpha_dash,
    message: "{_field_} must be valid",
  });

  extend("alpha_spaces", {
    ...alpha_spaces,
    message: "{_field_} must be valid",
  });

  export default defineComponent({
    props: ["user_id"],
    components: { ValidationProvider, ValidationObserver },
    setup(props, context) {
      const { userBank } = useActions(["userBank"]);
      const { getters_single_staff } = useGetters(["getters_single_staff"]);

      const bankForm = reactive({
        bankName: null,
        branch: null,
        accountNumber: null,
        observer: null,
        loading: false,
      });

      const { observer, bankName, accountNumber, branch, loading } =
        toRefs(bankForm);

      const sendData = async () => {
        await observer.value.validate().then((result) => {
          if (result) {
            loading.value = true;
            let data = {
              bank_name: bankName.value,
              account_number: accountNumber.value,
              branch: branch.value,
              id: getters_single_staff.value.id,
            };
            userBank(data)
              .then(() => {
                context.emit("page", { bool: true, page: 2, name: "bank" });
              })
              .catch(() => {
                context.emit("page", { bool: false, page: 2 });
              })
              .finally(() => (loading.value = false));
          } else {
            context.emit("page", { bool: false, page: 2 });
          }
        });
      };

      const addNewBank = async () => {
        await observer.value.validate().then((result) => {
          if (result) {
            let data = {
              id: props.user_id,
              bank_name: bankName.value,
              account_number: accountNumber.value,
              branch: branch.value,
            };
            userBank(data);
          }
        });
      };

      return {
        ...toRefs(bankForm),
        sendData,
        props,
        addNewBank,
      };
    },
  });
</script>
